<template>
  <div class="OperatingReportsList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-datepicker label="统计时间" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-select label="订单类型" v-model="searchParams.orderType" :options="orderTypeOps"></v-select>
        <v-select2 label="整合方" v-model="searchParams.integrationId" v-bind="integrationParams"></v-select2>
      </template>
    </list>
  </div>
</template>

<script>
import { regionParams } from 'common/select2Params'
import { getListURL, exportListURL, getIntegrationsURL } from './api'
import {
  setOrderTypeOps
} from './map'
import moment from 'moment'
export default {
  name: 'OperatingReportsList',
  data () {
    let time = moment().subtract(1, 'days').format('YYYY-MM-DD')
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      orderTypeOps: setOrderTypeOps(1),
      regionParams,
      searchParams: {
        startDate: time,
        endDate: time,
        regionId: '',
        orderType: undefined,
        integrationId: ''
      },
      integrationParams: {
        searchUrl: getIntegrationsURL,
        request: {
          text: 'integrationName',
          value: 'integrationId'
        }
      },
      headers: [
        {
          prop: 'date',
          label: '统计日期'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'orderType',
          label: '订单类型'
        },
        {
          prop: 'integrationName',
          label: '整合方'
        },
        {
          prop: 'payType',
          label: '支付类型'
        },
        {
          prop: 'orderCount',
          label: '订单量(笔)',
          align: 'right'
        },
        {
          prop: 'orderAmount',
          label: '交易额(元)',
          align: 'right'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
