// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}statisticsReport/getRegionOperationReport`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}statisticsReport/exportRegionOperationReport`
// 整合方 select2
const getIntegrationsURL = `${API_CONFIG.baseURL}serverCodewordAction!getIntegrations.action`

export {
  getListURL,
  exportListURL,
  getIntegrationsURL
}
