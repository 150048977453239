import { mapHelper } from 'common/utils'

// 订单类型
const orderType = [
  {
    text: '项目订单',
    value: 0
  }, {
    text: '商家订单',
    value: 1
  }
]

const {
  map: orderTypeMap,
  setOps: setOrderTypeOps
} = mapHelper.setMap(orderType)

export {
  orderTypeMap,
  setOrderTypeOps
}
