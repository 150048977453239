var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OperatingReportsList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          hasOperateColumn: false,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "统计时间",
                    startTime: _vm.searchParams.startDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "订单类型", options: _vm.orderTypeOps },
                  model: {
                    value: _vm.searchParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orderType", $$v)
                    },
                    expression: "searchParams.orderType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "整合方" },
                      model: {
                        value: _vm.searchParams.integrationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "integrationId", $$v)
                        },
                        expression: "searchParams.integrationId",
                      },
                    },
                    "v-select2",
                    _vm.integrationParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }